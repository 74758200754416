import React from "react";

function Ticker() {
	return (
		<div className="bg-red-600 py-1 flex items-center px-4">
			<div className="bg-red-600 pr-3 flex items-center justify-center">
				<h3 className="text-white uppercase font-medium text-2xl ">
					BREAKING:
				</h3>
			</div>
			<marquee behavior="scroll" direction="left">
				<div className="flex align-items-center gap-x-5">
					{listText.map((text, index) => (
						<h4
							translate=""
							className="text-white font-bold text-2xl m-0 flex items-center">
							<img
								src="https://pepeunchained.com/assets/images/svg-icons/dot.svg"
								alt=""
								height="20"
								width="20"
								className="mb-1"
							/>
							<span translate="" className="px-3">
								{text}
							</span>
						</h4>
					))}
				</div>
			</marquee>
		</div>
	);
}

export default Ticker;

const listText = [
	"PEPE RELEASES WORLD'S FIRST MEME COIN LAYER 2",
	"PEPE UNCHAINED TAKES THE BLOCKCHAIN BY STORM",
	"$PEPU PRESALE PRICE RISING AGAIN SOON!",
	"EXPERTS SAY PEPE UNCHAINED DOUBLE STAKING REWARDS = 100X THE LOLZ",
	"PEPE RELEASES WORLD'S FIRST MEME COIN LAYER 2",
	"PEPE UNCHAINED TAKES THE BLOCKCHAIN BY STORM",
	"$PEPU PRESALE PRICE RISING AGAIN SOON!",
	"EXPERTS SAY PEPE UNCHAINED DOUBLE STAKING REWARDS = 100X THE LOLZ",
];
