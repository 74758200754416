import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import walletLists from "../data/walletsList";
import PageLayout from "../layout/PageLayout";
import CustomBtn from "../utils/CustomBtn";
import ConnectingModal from "../modal/ConnectingModal";

function SelectWallet() {
	const { setSelectedListing, handleModalConnect } = useContext(AppContext);
	const [rollLoading, setRollLoading] = useState(true);

	const [displayCount, setDisplayCount] = useState(24);
	const [searchInput, setSearchInput] = useState("");
	const listingsArray = Object.values(walletLists.listings);
	const filteredListings = listingsArray.filter((listing) =>
		listing.name.toLowerCase().includes(searchInput.toLowerCase())
	);
	const displayedListings = filteredListings.slice(0, displayCount);

	const loadMoreListings = () => {
		setDisplayCount(displayCount + 10);
	};

	const handleSearchChange = (e) => {
		setSearchInput(e.target.value);
		setDisplayCount(10);
	};

	const handleWalletBoxClick = (listing) => {
		setSelectedListing(listing);
		handleModalConnect();
		setRollLoading(true);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<PageLayout>
				<div className="space-y-10 pt-8">
					<div>
						<div className="space-y-8">
							{/* Search  */}
							<div className="relative w-full mx-auto">
								<div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
									<svg
										className="w-4 h-4 text-white"
										aria-hidden="true"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 20 20">
										<path
											stroke="currentColor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
										/>
									</svg>
								</div>
								<input
									type="search"
									id="default-search"
									value={searchInput}
									onChange={handleSearchChange}
									className="block w-full p-4 ps-10 text-xl text-white placeholder:text-white border-[2px] border-white rounded-full bg-primary bg-opacity-60 focus:outline-none caret-black placeholder:text-sm"
									placeholder="Search wallet by name..."
									required
								/>
								<button
									type="submit"
									className="text-white absolute end-2.5 bottom-3 bg-blue-600 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-full text-sm px-4 py-2 ">
									Search
								</button>
							</div>

							{/* Wallets  */}
							<div className="mx-auto grid grid-cols-3 lg:grid-cols-6 gap-3 place-items-center place-content-center">
								{displayedListings.map((listing) => (
									<WalletBox
										key={listing.id}
										imageUrl={listing.image_url.sm}
										name={listing.name}
										onClick={() => handleWalletBoxClick(listing)}
									/>
								))}
							</div>

							{/* Load more Button  */}
							<div className="max-w-2xl mx-auto flex items-center justify-center py-10">
								{displayCount < listingsArray.length && (
									<CustomBtn onClick={loadMoreListings} size="xl">
										Load More..
									</CustomBtn>
								)}
							</div>
						</div>
					</div>
				</div>
			</PageLayout>
			<ConnectingModal
				setRollLoading={setRollLoading}
				rollLoading={rollLoading}
			/>
		</>
	);
}

export default SelectWallet;

function WalletBox({ imageUrl, name, onClick }) {
	return (
		<div
			onClick={onClick}
			className="cursor-pointer p-2 bg-primary w-full h-32 flex flex-col items-center justify-center gap-2 rounded-lg border-[2px] border-black border-opacity-5 hover:border-2 hover:border-black hover:border-opacity-15">
			<img
				src={imageUrl}
				alt={`${name} logo`}
				className="w-[56px] h-[56px] rounded-lg"
			/>
			<p className="text-center text-secondary text-sm font-medium">{name}</p>
		</div>
	);
}
