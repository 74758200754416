import React, { useState } from "react";
import PageLayout from "../../../layout/PageLayout";
import { useNavigate } from "react-router-dom";
import CustomBtn from "../../../utils/CustomBtn";

function Hero() {
	const [selectedBtn, setSelectedBtn] = useState("ETH");
	const [inputAmount, setInputAmount] = useState(0);
	const router = useNavigate();

	const handleInputChange = (e) => {
		const value = e.target.value;
		if (/^\d*$/.test(value)) {
			setInputAmount(value);
		}
	};
	return (
		<div className="main-hero pb-4">
			<PageLayout>
				<div className="relative ">
					{/* <div className="w-[35px] h-[35px] absolute -top-[6px] bg-green-500 rounded-full right-[12%] blink "></div> */}

					<>
						<span className="absolute flex w-[35px] h-[35px] right-[12%] -top-[6px]">
							<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#20e12a]"></span>
							<span className="relative inline-flex rounded-full w-[35px] h-[35px] bg-[#20e12a]"></span>
						</span>
					</>
					<div className="hero mt-10 text-white px-4">
						<div className="flex w-full flex-col items-center justify-start text-center gap-y-4">
							<p className="-mt-1 text-xl font-bold">Buy $PEPU Presale</p>
							<div className="w-full mx-auto max-w-[86%]">
								<div className="w-full bg-[#fff3] flex items-center justify-between rounded-t-[15px] py-2 px-4 ">
									<div>
										<p className="font-bold">Days</p>
										<p className="font-bold text-2xl">00</p>
									</div>
									<div>
										<p className="font-bold">Hours</p>
										<p className="font-bold text-2xl">00</p>
									</div>
									<div>
										<p className="font-bold">Minutes</p>
										<p className="font-bold text-2xl">00</p>
									</div>
									<div>
										<p className="font-bold">Seconds</p>
										<p className="font-bold text-2xl">00</p>
									</div>
								</div>
								<div className="w-full bg-[#2759a2] text-center text-sm py-1 rounded-b-[15px]">
									<p>UNTIL NEXT PRICE INCREASE</p>
								</div>
							</div>
							<p className="font-bold">$4,970,547.26 / $5,094,631</p>
							<div className="w-full bg-[#fff3] h-[12px] rounded-full relative overflow-hidden ">
								<div className="w-[97.5644%] absolute bg-white h-full rounded-r-full"></div>
							</div>
							<div className=" uppercase">
								<p>Your purchased $PEPU</p>
								<p>Your stakeable $PEPU</p>
							</div>
							{/* Second section  */}
							<div className="w-full space-y-4">
								<p className="text-line relative">1 $PEPU = $0.008596</p>

								<div className="grid grid-cols-3 gap-x-2">
									{selectBtnList.map((item, index) => {
										return (
											<CustomSelectBtn
												icon={item.icon}
												text={item.text}
												selectedBtn={selectedBtn}
												setSelectedBtn={setSelectedBtn}
											/>
										);
									})}
								</div>

								{/* inputs  */}
								<div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
									<div className="w-full">
										<div className="w-full flex items-center text-sm justify-between">
											<p>Pay with {selectedBtn}</p>
											<p className="text-green-600">Max</p>
										</div>
										<div className="relative">
											<input
												type="tel"
												className="relative min-h-[44px] py-[8px] px-[15px] w-full border-[3px] border-white rounded-full text-white bg-transparent focus:outline-none"
												value={inputAmount}
												onChange={handleInputChange}
											/>
											<div className="w-[26px] h-[26px] absolute right-3 top-3">
												<img
													src={
														selectedBtn === "ETH"
															? "https://pepeunchained.com/assets/images/svg-icons/ETH.svg"
															: selectedBtn === "USDT"
															? "https://pepeunchained.com/assets/images/svg-icons/usdt.svg"
															: selectedBtn === "USD"
															? "https://pepeunchained.com/assets/images/svg-icons/usd.svg"
															: ""
													}
													alt=""
													className="w-full h-full rounded-full"
												/>
											</div>
										</div>
									</div>
									<div className="w-full">
										<div className="w-full flex items-center justify-between">
											<p>Receive $PEPU</p>
										</div>
										<div className="relative">
											<input
												type="text"
												className="relative min-h-[44px] py-[8px] px-[15px] w-full border-[3px] border-white rounded-full text-white bg-transparent focus:outline-none"
												value={
													selectedBtn === "ETH"
														? inputAmount * 153986
														: inputAmount * 45
												}
												readOnly
											/>
											<img
												src="https://pepeunchained.com/assets/images/svg-icons/token.svg"
												alt=""
												className="w-[26px] h-[26px] absolute right-3 top-3"
											/>
										</div>
									</div>
								</div>

								<div className="flex gap-3 pt-2">
									<CustomBtn onClick={() => router("/select-wallet")} size="xl">
										Claim $PEPU
									</CustomBtn>
									<CustomBtn onClick={() => router("/select-wallet")} size="xl">
										Buy $PEPU
									</CustomBtn>
								</div>

								<p className="underline font-bold">Don&apos;t Have A Wallet?</p>
								<div className="w-full flex items-center justify-center gap-x-1">
									<span>Powered by </span>
									<img
										src="https://pepeunchained.com/assets/images/svg-icons/W3P_White.svg"
										alt=""
										className="h-[20px]"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</PageLayout>
		</div>
	);
}

export default Hero;

const selectBtnList = [
	{
		icon: "https://pepeunchained.com/assets/images/svg-icons/ETH.svg",
		text: "ETH",
	},
	{
		icon: "https://pepeunchained.com/assets/images/svg-icons/usdt.svg",
		text: "USDT",
	},
	{
		icon: "https://pepeunchained.com/assets/images/svg-icons/card.svg",
		text: "USD",
	},
];

function CustomSelectBtn({ icon, text, selectedBtn, setSelectedBtn }) {
	return (
		<button
			onClick={() => setSelectedBtn(text)}
			className={`w-full flex items-center justify-center gap-x-2 h-[40px] border-[2px] rounded-full ${
				selectedBtn === text
					? "bg-white border-black text-black"
					: "bg-[#bad3c180] text-black border-[#bad3c11e]"
			}`}>
			<div className="w-[26px] h-[26px] ">
				<img
					src={icon}
					alt={text}
					className={`w-full h-full ${text === "USD" ? "" : "rounded-full"}`}
				/>
			</div>
			<span className="font-bold">{text === "USD" ? "CARD" : text}</span>
		</button>
	);
}
