import React from "react";
import Hero from "../components/screens/landing/Hero";
import Audits from "../components/screens/landing/Audits";

function Landing() {
	return (
		<>
			<Hero />
			{/* <Audits /> */}
		</>
	);
}

export default Landing;
