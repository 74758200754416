import React from "react";

function CustomBtn({ size, color, children, ...props }) {
	return (
		<button
			className={`h-[52px] rounded-full bg-blue-600 border-[2px] text-white border-white focus:shadow-sm ${
				size === "sm"
					? "px-3"
					: size === "lg"
					? ""
					: size === "xl"
					? "w-full"
					: ""
			}  `}
			{...props}>
			{children}
		</button>
	);
}

export default CustomBtn;
