import React, { useState } from "react";
import Ticker from "../ticker/Ticker";
import { MenuIcon } from "../../assets/exportSvg";

function Header() {
	const [openMenu, setOpenMenu] = useState(false);

	console.log(openMenu);
	return (
		<>
			{/* Mobile  */}
			<header className="sticky top-0 w-full z-50">
				<Ticker />
				<div className="bg-primary flex items-center justify-between shadow-md py-2 px-4">
					<div>
						<a href="/">
							<img
								src="https://pepeunchained.com/assets/images/svg-icons/logo.svg"
								alt=""
							/>
						</a>
					</div>
					<div>
						<button onClick={() => setOpenMenu(!openMenu)}>
							<MenuIcon />
						</button>
					</div>
				</div>
			</header>

			{/* Mobile Menu  */}

			{openMenu && (
				<div
					className={`fixed h-screen z-30 inset-0 bg-primary transition-all duration-700 ${
						openMenu ? "left-0" : "left[100%]"
					} `}>
					<div className="pt-36 container mx-auto px-4">
						<ul className="">
							{navMenu.map((nav, index) => (
								<li className="py-4 border-b border-blue-600 font-semibold text-lg">
									{nav.name}
								</li>
							))}
						</ul>
					</div>
				</div>
			)}
		</>
	);
}

export default Header;

const navMenu = [
	{
		name: "Staking",
		link: "/",
	},
	{
		name: "About",
		link: "/",
	},
	{
		name: "How To Buy",
		link: "/",
	},
	{
		name: "Tokenomics",
		link: "/",
	},
	{
		name: "Roadmap",
		link: "/",
	},
	{
		name: "FAQs",
		link: "/",
	},
	{
		name: "White Paper",
		link: "/",
	},
];
